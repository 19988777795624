@font-face {
  font-family: "proxima_nova_rgregular";
  src: url("./fonts/proximanova-reg-webfont.eot");
  src: url("./fonts/proximanova-reg-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/proximanova-reg-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-reg-webfont.woff") format("woff"),
    url("./fonts/proximanova-reg-webfont.ttf") format("truetype"),
    url("./fonts/proximanova-reg-webfont.svg#proxima_nova_rgregular")
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}

@font-face {
  font-family: "proxima_nova_ththin";
  src: url("./fonts/proximanova-thin-webfont.eot");
  src: url("./fonts/proximanova-thin-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/proximanova-thin-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-thin-webfont.woff") format("woff"),
    url("./fonts/proximanova-thin-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}

@font-face {
  font-family: "proxima_nova_ltsemibold";
  src: url("./fonts/proximanova-sbold-webfont.eot");
  src: url("./fonts/proximanova-sbold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/proximanova-sbold-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-sbold-webfont.woff") format("woff"),
    url("./fonts/proximanova-sbold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-variant: normal;
}

@font-face {
  font-family: "proxima_nova_rgbold";
  src: url("./fonts/proximanova-bold-webfont.eot");
  src: url("./fonts/proximanova-bold-webfont.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/proximanova-bold-webfont.woff2") format("woff2"),
    url("./fonts/proximanova-bold-webfont.woff") format("woff"),
    url("./fonts/proximanova-bold-webfont.ttf") format("truetype"),
    url("./fonts/proximanova-bold-webfont.svg#proxima_nova_rgbold")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
